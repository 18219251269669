<div>
  <slot />
</div>

<style>
  div {
    border: none;
    border-radius: 4px;
    margin: 0 auto;
    background-color: white;
    width: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
</style>
