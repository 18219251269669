<script>
  import { createEventDispatcher } from 'svelte';
  import { createForm } from 'svelte-forms-lib';

  import api from '../../api';

  import { state } from '../../stores/state.store';
  import { transaction } from '../../stores/transaction.store';
  import { localError } from '../../stores/error.store';
  import timer from '../../stores/timer.store';

  import Button from '../../components/Button.svelte';
  import Input from '../../components/Input.svelte';

  import { general as generalStrings, seven as sevenStrings } from '../../resources/strings';
  import { setShape, setInitialValues } from './validation';

  const customQuestion = $state.customQuestion;

  const dispatch = createEventDispatcher();

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: setInitialValues,
    validationSchema: setShape,
    onSubmit: (submittedForm) =>
      api.postCode($transaction.id, 'set', submittedForm.appCode).then(() => {
        timer.resetTimer();
        dispatch('verificationSubmit');
      }),
  });
</script>

<form class="container" on:submit={handleSubmit}>
  <h3 class="title">{sevenStrings.appApprovalTitle}</h3>
  <p class="text">{sevenStrings.appApprovalText}</p>
  <p class="text">{sevenStrings.appApprovalCode(customQuestion)}</p>
  <ol>
    {#each sevenStrings.appVerifiactionSteps as step}
      <li>{step}</li>
    {/each}
  </ol>
  <Input
    label={sevenStrings.appCodePlaceholder}
    focus
    id="appCode"
    name="appCode"
    type="text"
    bind:value={$form.appCode}
    error={$errors.appCode}
    touched={$touched.appCode}
    {handleChange}
  />

  {#if $localError.verification}
    <p class="error">{$localError.verification}</p>
  {/if}

  <div class="button-container">
    <Button type="submit">{generalStrings.next}</Button>
  </div>
</form>

<style>
  .container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    place-items: center;
    gap: 1rem;
    padding: 0 2rem;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .title {
    font-weight: bold;
    text-align: center;
  }

  .text {
    margin: 10px 0;
  }

  .error {
    color: red;
    text-align: center;
  }
</style>
