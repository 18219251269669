<script>
  import SelectedBankInfo from './SelectedBankInfo.svelte';

  import { transaction } from '../stores/transaction.store';
  import { selectedBank } from '../stores/selected-bank.store';
</script>

<div class="transactionDetails">
  {#if $selectedBank.name !== ''}
    <SelectedBankInfo />
  {/if}
</div>

<style>
  .transactionDetails {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.3rem;
  }
</style>
