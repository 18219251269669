<script>
  import { aeon as aeonStrings } from '../../resources/strings';
</script>

<ul class="steps">
  {#each aeonStrings.appOtpAuthSteps as step}
    <li>{step}</li>
  {/each}
</ul>

<style>
  .steps {
    list-style: none;
  }
</style>
