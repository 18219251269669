import * as yup from 'yup';
import { errors } from '../../resources/strings';

const loginInitialValues = {
  customerNumber: '',
  password: '',
};

const loginShape = yup.object().shape({
  customerNumber: yup
    .string()
    .min(10, errors.incorrectLength10Digits)
    .max(10, errors.incorrectLength10Digits)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
  password: yup.string().required(errors.fieldRequired),
});

const customVerificationInitialValues = {
  custom: '',
  otpMethod: '',
};

const customVerificationShape = yup.object().shape({
  custom: yup.string().when('otpMethod', {
    is: false,
    then: yup
      .string()
      .min(4, errors.incorrectLength)
      .max(4, errors.incorrectLength)
      .required(errors.fieldRequired),
    otherwise: yup.string().notRequired(),
  }),
  otpMethod: yup.string().notRequired(),
});

const otpInitialValues = {
  otp: '',
};

const otpShape = yup.object().shape({
  otp: yup
    .string()
    .min(8, errors.incorrectLength)
    .max(8, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

export {
  loginInitialValues,
  loginShape,
  customVerificationInitialValues,
  customVerificationShape,
  otpInitialValues,
  otpShape,
};
