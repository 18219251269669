import { derived, writable } from 'svelte/store';
import { saveBankToLocalStore, getPreselectedBankName } from '../utils/preselectBankUtils';
import { banks } from './banks.store';
import { transaction } from './transaction.store';
import { shouldRedirectToManualTransfer } from '../utils/redirectUtils';

const initialSelectedBank = {
  name: '',
  nameJapanese: '',
  nameEnglish: '',
  loading: true,
};

const initialRedirectSemiBankName = '';

let setBank; // Forced derived store to become writable by caching set function here
let bankWasPreselected = false; // Singleton

const selectedBank = derived([transaction, banks], setPreselectedBank, initialSelectedBank);
const selectedBankForRedirect = writable(initialRedirectSemiBankName);

function setPreselectedBank([$transaction, $banks], set) {
  setBank = set;
  if (bankWasPreselected === true) return;

  if ($transaction.id !== '' && $banks.length > 0) {
    let preselectedBankName;

    if (bankWasPreselected === false) {
      preselectedBankName = getPreselectedBankName($transaction, $banks);

      bankWasPreselected = true;
    }

    if (preselectedBankName) {
      const shouldBeRedirected = shouldRedirectToManualTransfer(
        $transaction.semiRedirectBanks,
        preselectedBankName,
      );

      const manualAvailable = $banks.find((bank) => bank.name === 'manual');

      const preselectedBank = $banks.find(
        (bank) =>
          bank.name === (shouldBeRedirected && manualAvailable ? 'manual' : preselectedBankName),
      );

      if (preselectedBank) {
        const { maintenance, operatingHours } = preselectedBank;
        maintenance?.enabled || operatingHours?.enabled
          ? resetSelectedBank()
          : set(preselectedBank);

        return;
      }
    }

    set({
      ...initialSelectedBank,
      loading: false,
    });
  }
}

function updateRedirectBank(bankName) {
  selectedBankForRedirect.set(bankName);
}

function setSelectedBank(bank) {
  setBank(bank);
  saveBankToLocalStore(bank.name);
}

function resetSelectedBank() {
  setBank({ name: '', nameJapanese: '', nameEnglish: '', loading: false });
  updateRedirectBank(initialRedirectSemiBankName);
}

export {
  selectedBank,
  selectedBankForRedirect,
  setSelectedBank,
  resetSelectedBank,
  updateRedirectBank,
};
export default selectedBank;
