function convertToDisplayPrice(price) {
  const convertedToString = `${price}`;

  if (convertedToString.length <= 3) {
    return convertedToString;
  }

  const thousands = convertedToString.slice(0, -3);
  const rest = convertedToString.slice(-3);
  return `${thousands},${rest}`;
}

export { convertToDisplayPrice };
export default convertToDisplayPrice;
