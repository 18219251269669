import * as yup from 'yup';
import { errors } from '../../resources/strings';

const loginInitialValues = {
  accountNumber: '',
  password: '',
};

const loginShape = yup.object().shape({
  accountNumber: yup.string().max(20, errors.incorrectLength).required(errors.fieldRequired),

  password: yup
    .string()
    .min(6, errors.incorrectLength)
    .max(10, errors.incorrectLength)
    .required(errors.fieldRequired),
});

const emailCodeInitial = {
  verificationCode: '',
};

const emailCodeShape = yup.object().shape({
  verificationCode: yup
    .string()
    .length(6, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

const customInitial = {
  verificationCode: '',
};

const customShape = yup.object().shape({
  verificationCode: yup.string().required(errors.fieldRequired),
});

const birthDateInitial = {
  birthDate: '',
};

const birthDateShape = yup.object().shape({
  birthDate: yup
    .string()
    .length(8, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

export {
  loginInitialValues,
  loginShape,
  emailCodeInitial,
  emailCodeShape,
  customInitial,
  customShape,
  birthDateInitial,
  birthDateShape,
};
