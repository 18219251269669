<script>
  import { createEventDispatcher } from 'svelte';
  import { state } from '../stores/state.store';
  import { generalError } from '../stores/error.store';
  import { timer } from '../stores/timer.store';
  const dispatch = createEventDispatcher();

  $: minutes = Math.floor($timer / 60);
  $: seconds = $timer % 60;

  $: if ($timer === 0) {
    if ($state.transactionResult === '' && $generalError === '') {
      dispatch('timeout');
    }
  }

</script>

<div class="timer-container">
  <p>{minutes}:{seconds > 9 ? seconds : '0' + seconds}</p>
</div>

<style>
  p {
    font-size: 0.8rem;
    font-weight: bold;
  }
</style>
