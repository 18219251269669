import { writable } from 'svelte/store';
import { getCurrentState } from '../api';
import { updateError } from './error.store';

export { state, updateState, updateStateWithPlaceholder, resetState };

const initialState = {
  current: 'login',
  customQuestion: '',
  transactionResult: '',
};

const state = writable(initialState);

async function updateState(id) {
  state.update((prevState) => ({ ...prevState, current: 'loading' }));
  let newState;
  try {
    newState = await getCurrentState(id);
    state.update(() => newState);
  } catch (e) {
    updateError('Unknown');
  }
}

async function updateStateWithPlaceholder(id, placeholder) {
  state.update((prevState) => ({ ...prevState, current: placeholder }));
  let newState;
  try {
    newState = await getCurrentState(id);
    state.update(() => newState);
    return newState;
  } catch (e) {
    updateError('Unknown');
    return 'Unknown';
  }
}

function resetState() {
  state.update(() => initialState);
}
