<script>
  import { createEventDispatcher } from 'svelte';
  import { fly } from 'svelte/transition';

  import { japanpost as bankStrings, general as generalStrings } from '../../resources/strings';

  import Button from '../../components/Button.svelte';
  import LoginForm from './LoginForm.svelte';
  import InfoPage from './InfoPage.svelte';

  const dispatch = createEventDispatcher();

  export let loginMethod = '';

  function back() {
    dispatch('back');
  }

  function backToLogin() {
    loginMethod = '';
  }

  function setLoginMethod(method) {
    loginMethod = method;
  }

  function setLoginMethodToBiometrics() {
    setLoginMethod('biometrics');
  }
</script>

{#if !loginMethod}
  <div class="container" in:fly={{ x: -200, duration: 300 }}>
    <p class="title">{bankStrings.loginMethodTitle}</p>
    <p class="biometric-info">{bankStrings.informationOnBiometricLogin}</p>
    <div class="login-buttons-container">
      <Button on:click={() => setLoginMethod('password')}>{bankStrings.passwordMethod}</Button>
      <Button on:click={() => setLoginMethod('biometricsInfo')}
        >{bankStrings.biometricsMethod}</Button
      >
    </div>
    <div class="back-button-container">
      <Button on:click={back} color="#b71c1c">{generalStrings.back}</Button>
    </div>
  </div>
{:else if loginMethod == 'biometricsInfo'}
  <InfoPage on:setLoginMethodToBiometrics={setLoginMethodToBiometrics} on:back={back} />
{:else}
  <LoginForm {loginMethod} on:backToLogin={backToLogin} on:loginSubmit />
{/if}

<style>
  .container {
    padding: 0 2rem;
  }
  .title {
    text-align: center;
    margin-bottom: 12px;
  }
  .biometric-info {
    margin-bottom: 20px;
  }

  .login-buttons-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }

  .back-button-container {
    text-align: center;
  }

  @media (max-width: 650px) {
    .login-buttons-container {
      flex-direction: column;
      justify-content: center;
    }
  }
</style>
