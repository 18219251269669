<script>
  import { Circle } from 'svelte-loading-spinners';
  import progress from '../stores/transaction-progress.store';
</script>

<div class="container">
  <Circle />
  {#if $progress > 0}
    <p class="progress">{$progress}%</p>
  {/if}
</div>

<style>
  .container {
    width: 100%;
    display: grid;
    place-items: center;
    position: relative;
  }
  .progress {
    position: absolute;
    font-size: 1.3rem;
  }
</style>
