<script>
  import { createEventDispatcher } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import { fly } from 'svelte/transition';
  import { transaction } from '../../stores/transaction.store';
  import { selectedBank } from '../../stores/selected-bank.store';
  import { localError, setError, errors as errorsConst } from '../../stores/error.store';
  import { startProgress, progress } from '../../stores/transaction-progress.store';
  import { seven as sevenStrings } from '../../resources/strings';
  import { loginShape, loginInitialValues } from './validation';

  import Input from '../../components/Input.svelte';
  import LoginButtonsGroup from '../../containers/LoginButtonsGroup.svelte';
  import api from '../../api';

  const dispatch = createEventDispatcher();

  let submitted = false;

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: loginInitialValues,
    validationSchema: loginShape,
    onSubmit: (submittedForm) => {
      if (!submitted) {
        submitted = true;
        return api
          .startCrawler(
            $transaction.id,
            {
              accountNumber: submittedForm.login,
              password: submittedForm.password,
            },
            $selectedBank.name,
          )
          .then(async (res) => {
            if (res?.ok === false) {
              $progress = 0;
              const data = await res.json();
              if (data.current) {
                setError(data.current, data);
              } else {
                setError(errorsConst.Unknown);
              }
            } else {
              startProgress();
              dispatch('loginSubmit');
            }
          });
      }
    },
  });
</script>

<form on:submit={handleSubmit} class="container">
  <div>
    <div class="login-form" in:fly={{ x: -200, duration: 300 }}>
      <Input
        id="login"
        name="login"
        type="text"
        maxlength="32"
        label={sevenStrings.login}
        placeholder={sevenStrings.loginPlaceholder}
        bind:value={$form.login}
        error={$errors.login}
        touched={$touched.login}
        {handleChange}
      />
      <Input
        name="password"
        type="password"
        maxlength="32"
        label={sevenStrings.password}
        bind:value={$form.password}
        error={$errors.password}
        touched={$touched.password}
        {handleChange}
      />
    </div>
    {#if $localError.login}
      <p class="error">{$localError.login}</p>
    {/if}
    <LoginButtonsGroup on:back />
  </div>
</form>

<style>
  .container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    padding: 0 2rem;
  }
  .login-form {
    display: flex;
    flex-direction: column;
  }
  .error {
    color: red;
  }
</style>
