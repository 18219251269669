const tfOuput = require('./tf_output.json');

// TODO apiUrl: route that to domain via dns, so getting it manually form sls info won't be needed anymore
const apiUrl = 'https://b4hshqs89b.execute-api.ap-northeast-1.amazonaws.com';
const browserProxyAddress = `http://${tfOuput.web_proxy_private_ip.value}:8118`;
const dbCluster = tfOuput.documentdb_endpoint.value;
const bucketName = 'aph-test-puppeteer-screenshots';
const merchantsBucketName = 'aph-test-backgrounds';
const fxmarketURL = 'https://fxmarketapi.com';
const fxMarketApiKey = '6zEqW9BHCr0gp9ugHO16';
const ppjProcessingURL = 'merchant4.acm-processing.com';
const ppjProcessingKey = '0810055182e23220bc5fa1d1e7e913b2f577bd4c';
const dbUsername = 'aphadmin';
const dbName = 'sample-database';
// const dbHost = "db.test.t-paytech.com"; // subdomain for widget domain
const dbHost = dbCluster;
const region = 'ap-northeast-1';
const aphEnv = 'test';
const testMerchant = '61b9e5a952bbbca9b2e35799';
const transactionLimitPerDay = 8;
const minTransferAmount = 10;
const maxTransferAmount = 2.15 * 1000 * 1000;
const unsettledPendingsLimit = 2;
const ftdUnsettledPendingsLimit = 1;
const minSuccessAmount = 40_000;
const nftdFlowStartDate = '2024-03-11';
const ftdTransferLimitAmount = 10_000;
const adminTimezone = { timeZone: 'europe/warsaw' };
const banksLogosUrl =
  'https://aph-test-banks-logos.s3.ap-northeast-1.amazonaws.com';
const snsApiAlertsArn = tfOuput.sns_api_alert_arn.value;
const adminApiUrl =
  'https://cw7t4wo98i.execute-api.ap-northeast-1.amazonaws.com/';
const semiRecipientAccountsUrl = 'https://api.merchant.d-paytech.com';
const chromiumLayer =
  'arn:aws:lambda:ap-northeast-1:791485157843:layer:chromium-layer:2';

const lambdaRoleArn = tfOuput.aph_lambda_role.value;
const elbName = tfOuput.web_proxy_elb_name.value;
const defaultDailyLimits = {
  total: 20,
  failed: 10,
};

const widget = {
  domain: 'test.t-paytech.com',
  url: 'https://test.t-paytech.com',
  hostedZoneId: 'Z0250286OH6BC1PV9R8V',
  certArn:
    'arn:aws:acm:us-east-1:791485157843:certificate/7986f4c8-8f0d-4553-9e92-fa98c6d8388e',
};

const widgetPlayground = {
  domain: 'playground.test.t-paytech.com',
  url: 'https://playground.test.t-paytech.com',
  hostedZoneId: 'Z03189432CED3M7VRW7MP',
  certArn:
    'arn:aws:acm:us-east-1:791485157843:certificate/0014d02b-cb9f-41ea-9b12-5dc0420e98ea',
};

const admin = {
  domain: 'test.bo-paytech.com',
  url: 'https://test.bo-paytech.com',
  hostedZoneId: 'Z01000592KPJLTKK3CO9H',
  certArn:
    'arn:aws:acm:us-east-1:791485157843:certificate/5722f0b9-832c-4ae9-a572-ccda4bed7406',
};

const customAdmin = {
  domain: 'test-admin.bo-paytech.com',
  url: 'https://test-admin.bo-paytech.com',
  hostedZoneId: 'Z0965184LU7GYZDNRZS2',
  certArn:
    'arn:aws:acm:us-east-1:791485157843:certificate/a8892816-66bc-4aaf-bdc7-1527f1c60a3b',
};

const proxyEc2 = [
  {
    id: tfOuput.web_proxy_id.value,
    address: `http://${tfOuput.web_proxy_private_ip.value}:8118`,
    name: 'aph_test_web_proxy_ec2',
    dedicatedFor: [],
    vpnConfig: 'jp551.nordvpn.com.tcp.ovpn',
    vpnAccessId: 'Nord_VPN_1',
    vpnType: 'NordVPN',
  },
  {
    id: tfOuput.web_proxy_backup_id.value,
    address: `http://${tfOuput.web_proxy_backup_private_ip.value}:8118`,
    name: 'aph_test_web_proxy_ec2_backup',
    dedicatedFor: [],
    vpnConfig: 'tokyo-config-2.ovpn',
    vpnAccessId: '85049751',
    vpnType: 'ExpressVPN',
  },
];

const csvService = {
  name: 'aph-serverless-test-csvService',
  s3: 'aph-test-csv-bucket',
};

const accountsStatus = {
  domain: 'status-test.bo-paytech.com',
  url: 'https://status-test.bo-paytech.com',
  hostedZoneId: 'Z077013922ZUNRVIIHZ7T',
  certArn:
    'arn:aws:acm:us-east-1:791485157843:certificate/93614be1-5350-4b0a-8d7a-369f1e896403',
};

module.exports = {
  apiUrl,
  browserProxyAddress,
  dbCluster,
  bucketName,
  merchantsBucketName,
  fxmarketURL,
  fxMarketApiKey,
  ppjProcessingURL,
  ppjProcessingKey,
  dbUsername,
  dbHost,
  dbName,
  widget,
  widgetPlayground,
  admin,
  region,
  lambdaRoleArn,
  aphEnv,
  testMerchant,
  transactionLimitPerDay,
  minTransferAmount,
  maxTransferAmount,
  adminTimezone,
  banksLogosUrl,
  snsApiAlertsArn,
  customAdmin,
  adminApiUrl,
  elbName,
  proxyEc2,
  defaultDailyLimits,
  csvService,
  accountsStatus,
  semiRecipientAccountsUrl,
  chromiumLayer,
  unsettledPendingsLimit,
  ftdUnsettledPendingsLimit,
  minSuccessAmount,
  nftdFlowStartDate,
  ftdTransferLimitAmount,
};
