<script>
  import EyeOutline from 'svelte-material-icons/EyeOutline.svelte';
  import EyeOffOutline from 'svelte-material-icons/EyeOffOutline.svelte';

  export let value;
  export let label = '';
  export let error = '';
  export let handleChange;
  export let touched;

  let input;

  function toogleVisibility() {
    input.type = input.type === 'password' ? 'text' : 'password';
  }
</script>

<label for={$$props.name} class={$$restProps.classLabel ?? ''}>
  {#if label !== ''}
    <span>{label}</span>
  {/if}
  <div class="input-wrapper">
    <input
      {...$$props}
      id={$$props.name}
      on:change={handleChange}
      on:blur={handleChange}
      on:keydown
      bind:value
      bind:this={input}
    />
    {#if $$props.type === 'password'}
      <button type="button" on:click|preventDefault={toogleVisibility}>
        {#if input && input.type === 'password'}
          <EyeOutline width={20} height={20} />
        {:else}
          <EyeOffOutline width={20} height={20} />
        {/if}
      </button>
    {/if}
  </div>
  {#if error && touched}
    <small>{error}</small>
  {/if}
</label>

<style>
  label {
    width: 100%;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    gap: 0.5rem;
  }
  input {
    color: var(--gray-dark);
    font-size: 1rem;
    line-height: 2rem;
    width: 100%;
    text-align: left;
    border: none;
    border-bottom: 1px solid var(--gray-text-light);
    outline: none;
  }
  input:focus {
    border-bottom: 1px solid black;
    font-size: 16px !important;
  }

  small {
    color: red;
    font-size: 0.8rem;
  }

  .input-wrapper {
    display: flex;
    width: 100%;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
</style>
