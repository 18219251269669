<script>
  import { selectedBank } from '../stores/selected-bank.store';
  import mufg from '../banks/mufg/mufg.svelte';
  import paypay from '../banks/paypay/paypay.svelte';
  import smbc from '../banks/smbc/smbc.svelte';
  import rakuten from '../banks/rakuten/rakuten.svelte';
  import mizuho from '../banks/mizuho/mizuho.svelte';
  import resona from '../banks/resona/resona.svelte';
  import aujibun from '../banks/aujibun/aujibun.svelte';
  import gmoazora from '../banks/gmoazora/gmoazora.svelte';
  import sbisumishin from '../banks/sbisumishin/sbisumishin.svelte';
  import japanpost from '../banks/japanpost/japanpost.svelte';
  import seven from '../banks/seven/seven.svelte';
  import saitamaresona from '../banks/saitamaResona/saitamaResona.svelte';
  import manual from '../banks/manual/manual.svelte';
  import test from '../banks/test/test.svelte';
  import testFailed from '../banks/test/testFailed.svelte';
  import aeon from '../banks/aeon/aeon.svelte';
  import fukuoka from '../banks/fukuoka/fukuoka.svelte';
  import sony from '../banks/sony/sony.svelte';
  import suruga from '../banks/suruga/suruga.svelte';
  import hokkaido from '../banks/hokkaido/hokkaido.svelte';
  import chiba from '../banks/chiba/chiba.svelte';
  import hiroshima from '../banks/hiroshima/hiroshima.svelte';
  import kagoshima from '../banks/kagoshima/kagoshima.svelte';
  import tokyostar from '../banks/tokyostar/tokyostar.svelte';
  import awa from '../banks/awa/awa.svelte';
  import kyoto from '../banks/kyoto/kyoto.svelte';
  import hokuriku from '../banks/hokuriku/hokuriku.svelte';
  import kansaimirai from '../banks/kansaimirai/kansaimirai.svelte';
  import musashino from '../banks/musashino/musashino.svelte';
  import shinsei from '../banks/shinsei/shinsei.svelte';
  import shizuoka from '../banks/shizuoka/shizuoka.svelte';
  import yokohama from '../banks/yokohama/yokohama.svelte';

  const bankComponents = {
    mufg,
    paypay,
    smbc,
    rakuten,
    mizuho,
    resona,
    gmoazora,
    sbisumishin,
    test,
    aujibun,
    japanpost,
    seven,
    saitamaresona,
    manual,
    testFailed,
    aeon,
    fukuoka,
    sony,
    suruga,
    hokkaido,
    chiba,
    hiroshima,
    kagoshima,
    tokyostar,
    awa,
    kyoto,
    hokuriku,
    kansaimirai,
    musashino,
    shinsei,
    shizuoka,
    yokohama,
  };
</script>

<svelte:component this={bankComponents[$selectedBank.name]} on:back />
