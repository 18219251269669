import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

function isUnderMaintenancePeriod(bank) {
  const maintenanceEnabled = bank.maintenance?.enabled ?? false;
  const startDate = bank.maintenance?.startDate;
  const endDate = bank.maintenance?.endDate;

  if (!maintenanceEnabled) {
    return false;
  }

  if (maintenanceEnabled && !startDate && !endDate) {
    return true;
  }

  const currentJapaneseDate = getCurrentJPDate();
  return isWithinRange(startDate, endDate, currentJapaneseDate);
}

function getCurrentJPDate() {
  const today = dayjs();
  return today.tz('Asia/Tokyo');
}

function isOutsideOfOperatingHours(options) {
  if (!options?.startTime || !options?.endTime || !options?.enabled) {
    return false;
  }
  const currentJapaneseDate = getCurrentJPDate();
  const todayStartDate = getDateWithTime(options.startTime);
  const todayEndDate = getDateWithTime(options.endTime);
  return !isWithinRange(todayStartDate, todayEndDate, currentJapaneseDate);
}

function getDateWithTime(date) {
  const { hour, minute, second } = getTimeFromUTCDate(date);

  return dayjs().tz('Asia/Tokyo').set('hour', hour).set('minute', minute).set('second', second);
}

function getTimeFromUTCDate(date) {
  const dayJsDate = dayjs(date);

  return {
    hour: dayJsDate.hour(),
    minute: dayJsDate.minute(),
    second: dayJsDate.second(),
  };
}

function isWithinRange(startDate, endDate, date) {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  return date.isBetween(start, end);
}

export { isUnderMaintenancePeriod, isOutsideOfOperatingHours, getDateWithTime };
