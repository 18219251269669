import { writable } from 'svelte/store';

export {
  manualTransaction,
  resetManualTransaction,
  setManualTransaction,
  updateManualTransactionStatus,
};

const manualTransaction = writable({
  transaction: null,
  status: '',
});

function setManualTransaction(transaction) {
  manualTransaction.update((prevState) => ({ ...prevState, transaction }));
}

function updateManualTransactionStatus(status) {
  manualTransaction.update((prevState) => ({ ...prevState, status }));
}

function resetManualTransaction() {
  manualTransaction.set({
    transaction: null,
    status: '',
  });
}
