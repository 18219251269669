<!-- svelte-ignore a11y-click-events-have-key-events -->
<script>
  import { general as generalStrings } from '../resources/strings';

  import BankLogo from '../components/BankLogo.svelte';
  import LabeledText from '../components/LabeledText.svelte';
  import {
    getDateWithTime,
    isOutsideOfOperatingHours,
    isUnderMaintenancePeriod,
  } from '../utils/dateUtils';

  export let bank;
  export let checked = false;

  $: maintenance = isUnderMaintenancePeriod(bank);
  $: outsideOfOperatingHours = isOutsideOfOperatingHours(bank.operatingHours);

  $: disabled = maintenance || outsideOfOperatingHours;

  let dispalyedStartTime;
  let displayedEndTime;

  $: if (outsideOfOperatingHours) {
    const { startTime, endTime } = bank.operatingHours;
    const startDate = getDateWithTime(startTime);
    const endDate = getDateWithTime(endTime);

    dispalyedStartTime = `${startDate.format('HH:mm')}`;
    displayedEndTime = `${endDate.format('HH:mm')}`;
  }
</script>

<div on:click class="container" class:disabled>
  <div class="inner-container">
    <input type="radio" {checked} {disabled} />
    <LabeledText label={bank.nameEnglish} text={bank.nameJapanese} />
    <div class="logo-container">
      <BankLogo bankName={bank.name} />
    </div>
  </div>
  {#if maintenance}
    <p class="message">{generalStrings.bankDisabled}</p>
  {:else if outsideOfOperatingHours}
    <p class="message">
      {generalStrings.operatingHours(dispalyedStartTime, displayedEndTime, bank.nameJapanese)}
    </p>
  {/if}
</div>

<style>
  .container {
    padding: 0.5rem 2rem 0.5rem 1rem;
    border-top: 1px solid var(--gray-interface);
    border-bottom: 1px solid var(--gray-interface);
  }

  .inner-container {
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .disabled {
    background-color: var(--gray-light);
    cursor: not-allowed;
  }

  @media (max-width: 550px) {
    .container {
      padding: 0.5rem;
    }
  }

  .container:not(.disabled):hover,
  .container:not(.disabled):active {
    cursor: pointer;
    background-color: var(--gray-light);
  }

  .logo-container {
    display: flex;
    justify-content: flex-end;
  }

  .message {
    margin-top: 10px;
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 300;
    color: var(--red-dark);
    text-align: center;
  }
</style>
