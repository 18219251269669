<script>
  import { createEventDispatcher, onMount } from 'svelte';

  import api from '../../api';

  import { japanpost, general as generalStrings } from '../../resources/strings';

  import { transaction } from '../../stores/transaction.store';
  import { localError } from '../../stores/error.store';
  import timer from '../../stores/timer.store';

  import Button from '../../components/Button.svelte';

  export let codeType;
  export let customQuestion;
  export let initialTimer = 5 * 60 - 5; // 4:55

  const dispatch = createEventDispatcher();

  onMount(() => timer.setNewTimer(initialTimer));

  function submitBiometricLogin() {
    api.postCode($transaction.id, codeType, 'true').then(() => {
      dispatch('qrCodeVerificationSubmit');
      timer.resetTimer();
    });
  }
</script>

<div class="container">
  <p>{japanpost.qrCodeTitle}</p>
  <p>{japanpost.qrCodeDescription}</p>
  <img class="qr-code" src={customQuestion} alt="Qr Code" />
  <p>{japanpost.qrCodeLogin}</p>
  {#if $localError.verification}
    <p class="error">{$localError.verification}</p>
  {/if}
  <div class="button-container">
    <Button on:click={submitBiometricLogin}>{generalStrings.next}</Button>
  </div>
</div>

<style>
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 0 clamp(8px, 2.6%, 30px);
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .error {
    color: red;
  }
  .qr-code {
    width: clamp(160px, 100%, 400px);
    height: auto;
  }
</style>
