<script>
  import { createEventDispatcher } from 'svelte';
  import api from '../../api';
  import { general as generalStrings, smbc } from '../../resources/strings';
  import { transaction } from '../../stores/transaction.store';
  import { state } from '../../stores/state.store';
  import timer from '../../stores/timer.store';
  import AccountItem from './AccountItem.svelte';
  import SelectButton from '../../components/SelectButton.svelte';

  const dispatch = createEventDispatcher();

  let selectedValue;
  $: accountsList = $state.customQuestion ? JSON.parse($state.customQuestion) : [];
  $: console.log(accountsList);
  $: console.log(selectedValue);

  function handleSubmit() {
    api.postCode($transaction.id, 'custom', selectedValue).then(() => {
      timer.resetTimer();
      dispatch('verificationSubmit');
    });
  }
</script>

<form class="container">
  <div class="info">{smbc.withdrawalAccountSelection}</div>
  {#each accountsList as account (account.accountNumber)}
    <AccountItem
      on:click={() => (selectedValue = account.accountPosition)}
      isActive={selectedValue == account.accountPosition}
      {account}
    />
  {/each}
  <div class="button-container">
    <SelectButton type="button" on:click={handleSubmit} disabled={selectedValue == null}
      >{generalStrings.next}</SelectButton
    >
  </div>
</form>

<style>
  .container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 3rem;
    padding: 0 2rem;
  }

  .info {
    width: 100%;
    font-weight: bold;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: end;
  }
</style>
