<button {...$$props} class="button" on:click>
  <slot />
</button>

<style>
  .button {
    border: none;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(21, 114, 163, 0.2);
    padding: 0.5rem 3rem;
    transition: all 0.3s ease 0s;
    background: var(--blue-main);
    color: var(--text-main-light);
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    font-style: normal;
    width: 100%;
    max-width: 600px;
    height: var(--main-button-height);
  }

  .button:hover,
  .button:focus {
    cursor: pointer;
    background: var(--blue-main-darker);
  }

  .button:active {
    box-shadow: inset 0 0.6em 2em -0.3em rgba(0, 0, 0, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
    background: var(--blue-main-dark);
  }

  .button:disabled {
    background: var(--blue-main-light);
    cursor: not-allowed;
  }
</style>
