<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import { general as generalStrings } from '../../resources/strings';
  import { customVerificationShape, customVerificationInitialValues } from './validation';
  import { transaction } from '../../stores/transaction.store';
  import { localError } from '../../stores/error.store';
  import { state } from '../../stores/state.store';
  import api from '../../api';
  import timer from '../../stores/timer.store';
  import Button from '../../components/Button.svelte';
  import Input from '../../components/Input.svelte';

  export let codeType;

  const customQuestion = JSON.parse($state.customQuestion);
  const dispatch = createEventDispatcher();

  onMount(() => timer.setNewTimer(115));

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: customVerificationInitialValues,
    validationSchema: customVerificationShape,
    onSubmit: (submittedForm) =>
      api.postCode($transaction.id, codeType, JSON.stringify(submittedForm)).then(() => {
        timer.resetTimer();
        dispatch('verificationSubmit');
      }),
  });

  onMount(() =>
    customQuestion.forEach(() => {
      $form.type = customQuestion.verificationType;
      $form.answers = $form.answers.concat('');
    }),
  );
</script>

<form class="form-container" on:submit={handleSubmit}>
  <div class="container">
    {#if codeType === 'custom'}
      {#each customQuestion as question, index}
        <Input
          label={question}
          id={`answers[${index}]`}
          name={`answers[${index}]`}
          type="text"
          maxlength="2"
          bind:value={$form.answers[index]}
          error={$errors.answers[index]}
          touched={$touched.answers[index]}
          {handleChange}
        />
      {/each}
    {/if}
    {#if $localError.verification}
      <p class="error">{$localError.verification}</p>
    {/if}
    <div class="button-container">
      <Button type="submit">{generalStrings.next}</Button>
    </div>
  </div>
</form>

<style>
  .form-container {
    width: 100%;
  }
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .error {
    color: red;
  }
</style>
