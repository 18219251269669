<script>
  import { onMount } from 'svelte';
  import { transaction } from '../../stores/transaction.store';
  import { state, updateStateWithPlaceholder } from '../../stores/state.store';
  import api from '../../api';
  import { sbi as sbiStrings } from '../../resources/strings';
  import NumbersDisplay from '../../components/NumbersDisplay.svelte';
  import Loading from '../../components/Loading.svelte';
  import timer from '../../stores/timer.store';
  import { localError, updateError } from '../../stores/error.store';

  $: verificationData = JSON.parse($state.customQuestion);
  let initialNumbers;
  let isTransactionVerification = false;
  let isFakeLoading = false;

  const setComponentInitialState = () => {
    timer.setNewTimer(8 * 60 - 5);
    api.postCode($transaction.id, 'appAuth', 'ready');
    updateStateWithPlaceholder($transaction.id, 'appAuth')
      .then((newState) => updateError(newState.current))
      .catch((e) => updateError(e));
  };

  onMount(() => {
    initialNumbers = verificationData.numbers.join();
  });

  $: if ($state.current.includes('Retry')) {
    setComponentInitialState();
  }

  $: if (initialNumbers !== verificationData.numbers.join()) {
    if (verificationData.next === 'TransactionResult') {
      isFakeLoading = true;
      isTransactionVerification = true;
      setTimeout(() => {
        isFakeLoading = false;
      }, 7000);
    }
    // Sometimes transaction creation might be faster than front end
    // - this will keep this component in view, without triggering API action
    setComponentInitialState();
  }
</script>

{#if isFakeLoading}
  <Loading />
{:else}
  <div class="container">
    {#if $localError.verification}
      <p class="error">{$localError.verification}</p>
    {/if}

    <h3 class="title">{sbiStrings.appAuthTitle}</h3>

    {#if isTransactionVerification}
      <p class="warning">{sbiStrings.appAuthTransactionWarning}</p>
      <p class="support-text">{sbiStrings.appAuthInfo}</p>
    {:else}
      <p class="support-text">{sbiStrings.appAuthLoginInfo}</p>
    {/if}

    <NumbersDisplay label={sbiStrings.appAuthTrxNumber} numbers={verificationData.numbers} />
  </div>
{/if}

<style>
  .container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    place-items: center;
    gap: 1rem;
    padding: 0 2rem;
  }

  .title {
    font-weight: bold;
    text-align: center;
  }

  .error {
    color: red;
    text-align: center;
  }
  .warning {
    font-size: 1.2rem;
    color: red;
    text-align: center;
  }
</style>
