<script>
  import LogRocket from 'logrocket';
  import { createForm } from 'svelte-forms-lib';

  import api from '../../api';

  import Button from '../../components/Button.svelte';
  import Input from '../../components/Input.svelte';
  import Card from '../../components/Card.svelte';
  import Timer from '../../components/Timer.svelte';

  import { state } from '../../stores/state.store';
  import transaction, { setTransactionDetails } from '../../stores/transaction.store';
  import { generalError, setError, errors as errorsConst } from '../../stores/error.store';

  import { nameSurnameShape, nameSurnameShapeInitialValues } from './validation';

  import {
    nameSurname as nameSurnameStrings,
    errors as errorStrings,
    general as generalStrings,
  } from '../../resources/strings';

  function handleTimeout() {
    if ($state.transactionResult === '' && $generalError === '') {
      $generalError = errorStrings.timeout;
      LogRocket.track('Timeout');
    }
  }

  let submitted = false;

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: nameSurnameShapeInitialValues,
    validationSchema: nameSurnameShape,
    onSubmit: (submittedForm) => {
      if (!submitted) {
        submitted = true;
        return api
          .patchTransactionDetails($transaction.id, {
            name: submittedForm.name,
            surname: submittedForm.surname,
          })
          .then(async (res) => {
            if (res?.ok === false) {
              const data = await res.json();
              if (data.current) {
                setError(data.current, data);
              } else {
                setError(errorsConst.Unknown);
              }
            } else {
              const { name, surname } = await res.json();

              setTransactionDetails({
                name,
                surname,
              });
            }
          });
      }
    },
  });
</script>

<Card>
  <Timer on:timeout={handleTimeout} />
  <form on:submit={handleSubmit} class="container">
    <p class="title">{nameSurnameStrings.title}</p>
    <Input
      name="surname"
      label={nameSurnameStrings.surname}
      placeholder={nameSurnameStrings.surnamePlaceholder}
      bind:value={$form.surname}
      error={$errors.surname}
      touched={$touched.surname}
      {handleChange}
    />
    <Input
      name="name"
      label={nameSurnameStrings.name}
      placeholder={nameSurnameStrings.namePlaceholder}
      bind:value={$form.name}
      error={$errors.name}
      touched={$touched.name}
      {handleChange}
    />
    <Button type="submit">{generalStrings.next}</Button>
  </form>
</Card>

<style>
  .title {
    padding: 1rem 0;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 500;
    color: var(--gray-text);
    text-align: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    padding: 3rem;
  }
</style>
