<script>
    import { fly } from 'svelte/transition';
    import Button from '../../components/Button.svelte';
    import { japanpost as JPBTexts, general as generalTexts} from '../../resources/strings';
    import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();

    function setLoginMethodToBiometrics(){
        dispatch('setLoginMethodToBiometrics');
    }

    function back() {
    dispatch('back');
  }
  </script>
  
  <div class="container" in:fly={{ x: -200, duration: 300 }}>
   
      <p class="text">{JPBTexts.prepareMobileDevice}</p>

    <div class="buttons-container">
        <Button on:click={back} color="#b71c1c">{generalTexts.back}</Button>
        <Button on:click={setLoginMethodToBiometrics}>{generalTexts.next}</Button>
    </div>
  </div>
  
  <style>
    .container {
      padding: 0 2rem;
    }

    .text {
      margin-bottom: 20px;
    }

    .buttons-container {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;
      justify-content: space-between;
    }

    @media (max-width: 650px) {
    .buttons-container {
      flex-direction: column-reverse;
      justify-content: center;
    }
  }
  </style>