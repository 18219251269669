<script>
  export let label = '';
  export let text = '';
  export let alignRight = false;
</script>

<div class="container" class:alignRight>
  <span class="label">{label}</span>
  <h3 class="text">{text}</h3>
</div>

<style>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .alignRight {
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
  }

  .label {
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 300;
    color: var(--gray-text-light);
  }
  .text {
    font-size: 1.1rem;
    color: var(--gray-dark);
    font-weight: bold;
  }
</style>
