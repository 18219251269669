<script>
  import { fly } from 'svelte/transition';

  import { transaction } from '../../stores/transaction.store';
  import { updateError } from '../../stores/error.store';
  import { state, updateState } from '../../stores/state.store';

  import { otpInitial, otpShape, smsCodeInitial, smsCodeShape } from './validation';
  import { isLoading, isLogin, isVerification } from '../../utils/stateUtils';

  import Loading from '../../components/Loading.svelte';
  import Login from './Login.svelte';
  import CodeVerification from '../../containers/CodeVerification.svelte';
  import ChooseAccount from './ChooseAccount.svelte';
  import TelephoneVerification from './TelephoneVerification.svelte';
  import QrCodeVerification from './QrCodeVerification.svelte';

  async function handleStateChange() {
    try {
      await updateState($transaction.id);
    } catch {
      $state.current = 'connectionLost';
    }
    updateError($state.current);
  }
</script>

<div class="card-content">
  {#if isLoading($state.current)}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      <Loading />
    </div>
  {:else if isLogin($state.current)}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      <Login on:back on:loginSubmit={handleStateChange} />
    </div>
  {:else if isVerification($state.current) && $state.current === 'custom'}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      <ChooseAccount on:verificationSubmit={handleStateChange} codeType={$state.current} />
    </div>
  {:else if isVerification($state.current) && $state.current === 'telephone'}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      <TelephoneVerification
        on:verificationSubmit={handleStateChange}
        codeType={$state.current.replace('Retry', '')}
        customQuestion={$state.customQuestion}
      />
    </div>
  {:else if isVerification($state.current) && $state.current === 'qrCode'}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      <QrCodeVerification
        codeType={$state.current.replace('Retry', '')}
        customQuestion={$state.customQuestion}
        on:qrCodeVerificationSubmit={handleStateChange}
      />
    </div>
  {:else if isVerification($state.current)}
    <div class="transition" in:fly={{ x: 200, duration: 300 }}>
      <CodeVerification
        codeType={$state.current.replace('Retry', '')}
        validations={{
          otp: {
            inital: otpInitial,
            shape: otpShape,
          },
          smsCode: {
            inital: smsCodeInitial,
            shape: smsCodeShape,
          },
        }}
        on:verificationSubmit={handleStateChange}
      />
    </div>
  {/if}
</div>

<style>
  .card-content {
    display: grid;
    place-items: center;
    padding: 3rem;
  }

  .transition {
    display: grid;
    place-items: center;
    width: 100%;
  }
  @media (max-width: 550px) {
    .card-content {
      padding: 2rem 0;
    }
  }
</style>
