<script>
  import Status from './Status/Status.svelte';
</script>

<div class="result-container">
  <Status type={'Success'} />
</div>

<style>
  .result-container {
    width: 100%;
    display: grid;
    place-items: center;
    gap: 2rem;
  }
</style>
