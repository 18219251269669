import { writable } from 'svelte/store';

function createTimer() {
  const { subscribe, set, update } = writable(5 * 60);

  function sub(...params) {
    const unsubscribe = subscribe(...params);
    const interval = setInterval(countDown, 1000);
    return () => {
      clearInterval(interval);
      resetTimer();
      unsubscribe();
    };
  }

  function countDown() {
    update((prevState) => prevState - 1);
  }

  function resetTimer() {
    set(5 * 60);
  }

  function setNewTimer(time) {
    set(time);
  }

  return {
    subscribe: sub,
    setNewTimer,
    resetTimer,
  };
}

export const timer = createTimer();

export default timer;
