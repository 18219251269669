<script>
  import { onMount } from 'svelte';

  import api from '../../api';

  import { transaction } from '../../stores/transaction.store';

  import Status from '../../containers/Status/Status.svelte';
  import Loading from '../../components/Loading.svelte';

  let loading = true;

  onMount(async () => {
    await api.startFailedTransaction($transaction.id);
    loading = false;
  });
</script>

{#if loading}
  <Loading />
{:else}
  <Status type="Failed" message="Failed" />
{/if}
