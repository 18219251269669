<script>
  import { createEventDispatcher } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import { fly } from 'svelte/transition';

  import { transaction } from '../../stores/transaction.store';
  import { selectedBank } from '../../stores/selected-bank.store';
  import { localError, setError, errors as errorsConst } from '../../stores/error.store';
  import { startProgress, progress } from '../../stores/transaction-progress.store';

  import Input from '../../components/Input.svelte';
  import LoginButtonsGroup from '../../containers/LoginButtonsGroup.svelte';

  import api from '../../api';
  import { resona as bankStrings } from '../../resources/strings';
  import { loginShape, loginInitialValues } from './validation';

  const dispatch = createEventDispatcher();

  let submitted = false;

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: loginInitialValues,
    validationSchema: loginShape,
    onSubmit: (submittedForm) => {
      if (!submitted) {
        submitted = true;
        return api
          .startCrawler(
            $transaction.id,
            {
              accountNumber: submittedForm.accountNumber,
            },
            $selectedBank.name,
          )
          .then(async (res) => {
            if (res?.ok === false) {
              $progress = 0;
              const data = await res.json();
              if (data.current) {
                setError(data.current, data);
              } else {
                setError(errorsConst.Unknown);
              }
            } else {
              startProgress();
              dispatch('loginSubmit');
            }
          });
      }
    },
  });
</script>

<form on:submit={handleSubmit} class="container">
  <div class="account-container" in:fly={{ x: -200, duration: 300 }}>
    <Input
      label={bankStrings.userId}
      id="accountNumber"
      name="accountNumber"
      type="text"
      placeholder={bankStrings.userIdPlaceholder}
      bind:value={$form.accountNumber}
      error={$errors.accountNumber}
      touched={$touched.accountNumber}
      {handleChange}
    />
  </div>
  {#if $localError.login}
    <div class="login-error">
      <p class="error">{$localError.login}</p>
    </div>
  {/if}
  <LoginButtonsGroup on:back />
</form>

<style>
  .container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    padding: 0 2rem;
  }

  .account-container {
    width: 100%;
  }

  .login-error {
    width: 100%;
    display: grid;
    place-items: center;
  }

  .error {
    color: red;
  }
</style>
