import * as yup from 'yup';
import { errors } from '../../resources/strings';

const loginInitialValues = {
  accountNumber: '',
};

const loginShape = yup.object().shape({
  accountNumber: yup
    .string()
    .min(6, errors.incorrectLength6To12Charts)
    .max(12, errors.incorrectLength6To12Charts)
    .required(errors.fieldRequired),
});

const otpInitial = {
  verificationCode: '',
};

const otpShape = yup.object().shape({
  verificationCode: yup
    .string()
    .length(7, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

const appAuthInitial = {
  verificationCode: '',
};

const appAuthShape = yup.object().shape({
  verificationCode: yup
    .string()
    .length(6, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

const customInitial = {
  verificationCode: '',
};

const customShape = yup.object().shape({
  verificationCode: yup
    .string()
    .min(3, errors.incorrectLength)
    .max(10, errors.incorrectLength)
    .required(errors.fieldRequired),
});

const customPasswordInitial = {
  verificationCode: '',
};

const customPasswordShape = yup.object().shape({
  verificationCode: yup.string().required(errors.fieldRequired),
});

export {
  loginInitialValues,
  loginShape,
  otpInitial,
  otpShape,
  appAuthInitial,
  appAuthShape,
  customInitial,
  customShape,
  customPasswordInitial,
  customPasswordShape,
};
