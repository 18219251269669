<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import api from '../../api';
  import { general as generalStrings, sony as sonyStrings } from '../../resources/strings';
  import { setShape } from './validation';
  import { localError } from '../../stores/error.store';
  import { transaction } from '../../stores/transaction.store';
  import { state } from '../../stores/state.store';
  import timer from '../../stores/timer.store';
  import Button from '../../components/Button.svelte';
  import Input from '../../components/Input.svelte';

  const [firstText, secondText] = $state.customQuestion.split(',');
  const dispatch = createEventDispatcher();

  onMount(() => timer.setNewTimer(5 * 60 - 5)); // 4:55

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: {
      pin: '',
      firstNumber: '',
      secondNumber: '',
    },
    validationSchema: setShape,
    onSubmit: (submittedForm) => {
      api.postCode($transaction.id, 'set', JSON.stringify(submittedForm)).then(() => {
        timer.resetTimer();
        dispatch('verificationSubmit');
      });
    },
  });
</script>

<form class="container" on:submit={handleSubmit}>
  <div class="answer-container">
    <Input
      label={sonyStrings.transactionPin}
      focus
      id="pin"
      name="pin"
      type="text"
      bind:value={$form.pin}
      error={$errors.pin}
      touched={$touched.pin}
      {handleChange}
    />

    <div class="instructions">{sonyStrings.authorizationNumberInstructions}</div>

    <div>
      <Input
        label={firstText}
        focus
        id="firstNumber"
        name="firstNumber"
        type="text"
        bind:value={$form.firstNumber}
        error={$errors.firstNumber}
        touched={$touched.firstNumber}
        {handleChange}
      />
    </div>

    <div>
      <Input
        label={secondText}
        focus
        id="secondNumber"
        name="secondNumber"
        type="text"
        bind:value={$form.secondNumber}
        error={$errors.secondNumber}
        touched={$touched.secondNumber}
        {handleChange}
      />
    </div>
  </div>
  {#if $localError.verification}
    <p class="error">{$localError.verification}</p>
  {/if}

  <div class="button-container">
    <Button type="submit">{generalStrings.next}</Button>
  </div>
</form>

<style>
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 0 2rem;
  }

  .answer-container {
    width: 100%;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .instructions {
    margin: 1rem 0;
  }

  .error {
    color: red;
  }
</style>
