const KEYS = {
  SYSTEM_ID: 'sys_id',
};

function saveToLocalStorage(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

function getFromLocalStorage(key) {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch {
    return null;
  }
}

export { KEYS, saveToLocalStorage, getFromLocalStorage };
