import * as yup from 'yup';
import { errors } from '../../resources/strings';
import { isHiragana } from 'wanakana';

yup.addMethod(yup.string, 'isHiraganaString', function (errorMessage) {
  return this.test('test-is-hiragana', errorMessage, function (value) {
    const { path, createError } = this;
    return isHiragana(value) || createError({ path, message: errorMessage });
  });
});

const loginInitialValues = {
  login: '',
  password: '',
};

const loginShape = yup.object().shape({
  login: yup
    .string()
    .matches(/^\d+$/g, errors.onlyNumbers)
    .length(10, errors.incorrectLength)
    .required(errors.fieldRequired),
  password: yup.string().required(errors.fieldRequired),
});

const verificationCodeInitial = {
  verificationCode: '',
};

const otpVerificationShape = yup.object().shape({
  verificationCode: yup
    .string()
    .length(6, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

const emailCodeVerificationShape = yup.object().shape({
  verificationCode: yup
    .string()
    .length(6, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
});

const transactionPasswordVerificationShape = yup.object().shape({
  transactionPassword: yup.string().required(errors.fieldRequired),
});

const emailSetInitialValues = {
  emailCode: '',
  transactionPassword: '',
};

const otpSetInitialValues = {
  otp: '',
  transactionPassword: '',
};

const emailSetShape = yup.object().shape({
  emailCode: yup
    .string()
    .length(6, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
  transactionPassword: yup.string().required(errors.fieldRequired),
});

const otpSetShape = yup.object().shape({
  otp: yup
    .string()
    .length(6, errors.incorrectLength)
    .matches(/^\d+$/g, errors.onlyNumbers)
    .required(errors.fieldRequired),
  transactionPassword: yup.string().required(errors.fieldRequired),
});

const questionAnswerShape = yup.object().shape({
  answer: yup
    .string()
    .isHiraganaString(errors.hiraganaOnly)
    .min(2, errors.incorrectLength)
    .max(10, errors.incorrectLength),
});

const hiddenCharVerificationInitialValues = {
  answers: [],
};

const hiddenCharVerificationShape = yup.object().shape({
  answers: yup
    .array()
    .of(
      yup
        .string()
        .length(2, errors.incorrectLength)
        .matches(/^\d+$/g, errors.onlyNumbers)
        .required(errors.fieldRequired),
    ),
});

export {
  loginInitialValues,
  verificationCodeInitial,
  loginShape,
  otpVerificationShape,
  emailCodeVerificationShape,
  transactionPasswordVerificationShape,
  emailSetShape,
  otpSetShape,
  emailSetInitialValues,
  otpSetInitialValues,
  questionAnswerShape,
  hiddenCharVerificationInitialValues,
  hiddenCharVerificationShape,
};
