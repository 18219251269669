import { writable } from 'svelte/store';
import progressPerBank from '../resources/progress-per-bank.js';

const PROGRESS_MAX = 95;

let interval;

const progress = writable(0);

function startProgress(selectedBank = 'default') {
  const countdown = progressPerBank[selectedBank];
  const tickRate = Math.floor(countdown / PROGRESS_MAX);

  interval = setInterval(
    () =>
      progress.update((prevProgress) => {
        if (prevProgress < PROGRESS_MAX) {
          return prevProgress + 1;
        } else {
          clearInterval(interval);
          return prevProgress;
        }
      }),
    tickRate,
  );
}

function stopProgress() {
  clearInterval(interval);
}

export { progress, startProgress, stopProgress };
export default progress;
