<script>
  export let color = '#94c247';
</script>

<button {...$$props} class="button {$$restProps.class ?? ''}" style="background: {color}" on:click>
  <slot />
</button>

<style>
  .button {
    border: none;
    border-radius: 4px;
    padding: 0.5rem 3rem;
    transition: all 0.3s ease 0s;
    color: var(--white);
    font-weight: bold;
    font-size: 1rem;
    box-shadow:
      inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.07),
      inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.05),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.03);
    text-align: center;
  }

  .button:hover,
  .button:focus {
    cursor: pointer;
    filter: brightness(90%);
  }

  .button:active {
    box-shadow:
      inset 0 0.6em 2em -0.3em rgba(0, 0, 0, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
  }
</style>
