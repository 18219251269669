<script>
  import CheckCircle from 'svelte-material-icons/CheckCircle.svelte';
  import { general as generalStrings } from '../../resources/strings';

  export let authorized_amount;
  export let currency;

  const color = '#7cc23d';
</script>

<CheckCircle width="100px" height="100px" {color} />
<h3 class="heading" style="color: {color}">
  {generalStrings.transactionSuccess}
</h3>
<p class="message">
  <span class="amount">
    {`${authorized_amount}${currency}`}
  </span>
  {generalStrings.transactionSuccessInfo}
</p>
<p class="message">
  {generalStrings.transactionSuccessWarning}
</p>

<style>
  .heading {
    font-size: 2rem;
    text-align: center;
  }

  .message {
    font-size: 1.3rem;
    text-align: center;
    padding: 0 2rem;
    overflow-wrap: break-word;
  }

  .amount {
    font-weight: bold;
  }
</style>
