import * as yup from 'yup';
import * as wanakana from 'wanakana';
import { errors as errorStrings } from '../../resources/strings';

const nameSurnameShapeInitialValues = {
  name: '',
  surname: '',
};

const nameSurnameShape = yup.object().shape({
  name: yup
    .string()
    .required(errorStrings.fieldRequired)
    .test('name', errorStrings.kanaError, (value) => {
      return wanakana.isKana(value);
    }),
  surname: yup
    .string()
    .required(errorStrings.fieldRequired)
    .test('surname', errorStrings.kanaError, (value) => {
      return wanakana.isKana(value);
    }),
});

export { nameSurnameShapeInitialValues, nameSurnameShape };
