<script>
  import { createEventDispatcher, onMount } from 'svelte';

  import api from '../../api';

  import { saitamaResona } from '../../resources/strings';
  import { transaction } from '../../stores/transaction.store';

  import Button from '../../components/Button.svelte';
  import timer from '../../stores/timer.store';

  export let initialTimer = 5 * 60 - 5; // 4:55

  const dispatch = createEventDispatcher();

  onMount(() => timer.setNewTimer(initialTimer));

  const handleSubmit = (e) => {
    e.preventDefault();
    api.postCode($transaction.id, 'custom', 'submitted').then(() => {
      dispatch('verificationSubmit');
      timer.resetTimer();
    });
  };
</script>

<form on:submit={handleSubmit} class="container">
  <h2 class="header">{saitamaResona.newAppTitle}</h2>

  <p class="info">{saitamaResona.appInstruction1}</p>
  <p class="info">{saitamaResona.appInstruction2}</p>
  <p class="info">{saitamaResona.appInstruction3}</p>

  <div class="button-container">
    <Button type="submit">{saitamaResona.appConfirm}</Button>
  </div>
</form>

<style>
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 2rem;
  }

  .header {
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
  }

  .info {
    color: red;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
</style>
