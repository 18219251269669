<script>
  import { onMount } from 'svelte';
  import timer from '../../stores/timer.store';
  import CustomQuestionVerification from './CustomQuestionVerification.svelte';
  import EmailBirthdayVerification from './EmailBirthdayVerification.svelte';
  import SetVerification from './SetVerification.svelte';

  export let codeType;

  onMount(() => timer.setNewTimer(5 * 60 - 5)); // 4:55
</script>

{#if codeType === 'custom'}
  <CustomQuestionVerification on:verificationSubmit />
{:else if codeType === 'emailCode'}
  <EmailBirthdayVerification on:verificationSubmit />
{:else}
  <SetVerification on:verificationSubmit />
{/if}
