<script>
  import { onMount } from 'svelte';

  import transaction from '../../stores/transaction.store';
  import progress, { stopProgress } from '../../stores/transaction-progress.store';
  import { generalError } from '../../stores/error.store';
  import { selectedBank } from '../../stores/selected-bank.store';

  import Card from '../../components/Card.svelte';
  import SuccessStatus from './SuccessStatus.svelte';
  import ErrorStatus from './ErrorStatus.svelte';
  import GlobalError from './GlobalError.svelte';
  import RetryButtons from '../RetryButtons.svelte';

  import { errors } from '../../resources/strings';
  import { isFuture } from '../../utils/bankUtils';

  export let message = '';
  export let type = 'Success';
  export let globalError = null;

  onMount(() => stopProgress());

  let authorized_amount = $transaction.originalAmount;
  let currency = $transaction.currency;
</script>

<Card>
  <div class="container">
    {#if type === 'Success'}
      <SuccessStatus {authorized_amount} {currency} />
    {:else if type === 'global'}
      <GlobalError {globalError} />
    {:else}
      <ErrorStatus {message} />
      {#if $progress !== 0 && $generalError !== errors.timeout && !isFuture($selectedBank)}
        <RetryButtons />
      {/if}
    {/if}
  </div>
</Card>

<style>
  .container {
    display: grid;
    place-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
  }
</style>
